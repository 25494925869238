$text-direction: ltr;
$rdirection: right;
$ldirection: left;
$opc_update: true;
$password_strengthen: true;
$mobile-phone: max-width 724px;
$button_red: #ff375a;
$payment_info_collapse: true;

$cr20: true;
// 2021 styling refresh.
$cr21: true;

$welcome15_offer: true;
$color_dove_gray_approx: #68696d;
$fonts_update: true;
